import { absoluteURL } from 'app/utils'

const encodePair = (key: string, value: string) => {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
}

export const stringifyQuery = (query: { [key: string]: any }) => {
  const values = []
  for (const key in query) {
    if (
      !Object.prototype.hasOwnProperty.call(query, key) ||
      typeof key !== 'string' ||
      key === ''
    ) {
      continue
    }

    const value = query[key]
    if (value instanceof Array) {
      for (const subValue of value) {
        values.push(encodePair(key, subValue))
      }
    } else if (value === false) {
      values.push(encodePair(key, '0'))
    } else if (value === true) {
      values.push(encodePair(key, '1'))
    } else if (value !== null) {
      values.push(encodePair(key, value))
    }
  }

  if (values.length === 0) return ''
  return `?${values.join('&')}`
}

/**
 * Given a internal route, it will return an object with the query params
 * @param route string
 * @returns { [key: string]: string}
 */
export const getRouteParams = (route: string) => {
  const url = new URL(absoluteURL(route))
  const params = new URLSearchParams(url.search)
  const result: { [key: string]: string } = {}
  for (const [key, value] of params) {
    result[key] = value
  }
  return result
}

export const routeWithParams = (
  route: string,
  utmParams: { [key: string]: string | string[] | undefined }
) => {
  const populatedUtmParams = Object.fromEntries(
    Object.entries(utmParams).filter(([, value]) => value !== undefined)
  )
  const utmParamsUrlEncoded = stringifyQuery(populatedUtmParams)
  return `${route}${utmParamsUrlEncoded}`
}

/**
 * By default, it will remove all query params from the route but you can exclude some of them
 * by passing an array of keys to exclude
 * @param route string
 * @param exclude string[]
 * @returns string
 */
export const routeWithoutQueryParams = (route: string, exclude: string[] = []) => {
  if (exclude.length > 0) {
    const params = getRouteParams(route)
    const filteredParams = Object.keys(params).reduce((acc: { [key: string]: string }, key) => {
      if (exclude.includes(key)) {
        acc[key] = params[key]
      }
      return acc
    }, {})
    const query = stringifyQuery(filteredParams)
    return route.split('?')[0] + query
  }

  return route.split('?')[0]
}

export const isN4hListingUrl = (url: string) => {
  return (
    url.startsWith('/nanny-listing/nanny-for-hire-in') ||
    url.match(/[^/]+\/nanny-listing\/nanny-for-hire-in.*/) !== null
  )
}

export const getDecodedUrlToken = (token: string | undefined) => {
  if (typeof token !== 'string') return

  return decodeURIComponent(token)
}
