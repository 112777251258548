import styled from 'styled-components'

import { gradientForType, GradientType } from '@ui/theme/gradients'

export type GradientContainerProps = {
  gradient?: GradientType
}

const GradientContainer = ({ gradient = 'neutral' }: GradientContainerProps) => (
  <Container $gradient={gradient} />
)

const Container = styled.div<{ $gradient: GradientType }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: ${({ $gradient }) => gradientForType($gradient)};
  background-repeat: no-repeat;
`

export default GradientContainer
