import { ParsedUrlQuery } from 'querystring'
import { NextRouter } from 'next/router'

import {
  OnboardingEmployeeInput,
  OnboardingEmployerInput,
  OnboardingFamilyInput,
  OnboardingFamilyListingInput,
  OnboardingNannyInput,
  OnboardingNannyListingInput,
  OnboardingUserInput,
  ProfileType,
} from '@graph/types/global.generated'
import {
  CACHE_TO_CLEAR_ON_USER_CREATION,
  ONBOARDING_STEP_ORDER,
  OnboardingCacheKey,
  OnboardingStep,
  OnboardingType,
} from '@ui/onboarding/constants'
import {
  dashboardPath,
  onboardingPath,
  payrollFamilyOnboardingPath,
  payrollNannyOnboardingPath,
  payrollOnboardingPath,
} from 'routes/static'
import { Locale } from 'locale/constants'
import { buildOnboardingPath } from 'routes/onboarding'
import { ViewerGlobalFragment } from '@graph/fragments/ViewerGlobal.generated'
import { captureSentryException } from 'app/utils'

import {
  OnboardingFamilyListingFragment,
  OnboardingFamilyProfileFragment,
  OnboardingNannyProfileFragment,
  OnboardingViewerFragment,
} from './fragments.generated'
import {
  PayrollOnboardingFamilyFragment,
  PayrollOnboardingUserFragment,
  PayrollOnboardingViewerFragment,
} from './payroll/fragments.generated'

export const getStepPath = (
  stepOrderIndex: number,
  onboardingOrder: OnboardingStep[],
  onboardingType: OnboardingType,
  profileType?: ProfileType,
  nextPath?: string
) => {
  const isPayroll = isPayrollOnboarding(onboardingType)
  const hasOnboardingSteps = onboardingOrder.length > 0
  const isValidStep = !!onboardingOrder[stepOrderIndex]

  if (!profileType) {
    return isPayroll ? payrollOnboardingPath : onboardingPath
  }

  if (!hasOnboardingSteps) {
    return isPayroll ? payrollOnboardingPath : onboardingPath
  }

  if (!isValidStep) {
    return isPayroll ? payrollOnboardingPath : nextPath || dashboardPath
  }

  if (isPayroll) {
    const basePath = isFamily(profileType)
      ? payrollFamilyOnboardingPath
      : payrollNannyOnboardingPath

    return basePath + '/' + onboardingOrder[stepOrderIndex]
  } else {
    return buildOnboardingPath({ profileType, subStep: onboardingOrder[stepOrderIndex] })
  }
}

export const isFamily = (profileType: ProfileType) => profileType === ProfileType.Family
export const isNanny = (profileType: ProfileType) => profileType === ProfileType.Nanny

export const parseLocalStorage = <T>(key: OnboardingCacheKey): T | undefined => {
  try {
    if (typeof window !== 'undefined') {
      const localStorageData = localStorage.getItem(key)
      return localStorageData ? JSON.parse(localStorageData) : ({} as T)
    }
  } catch (error) {
    //skip if local storage is not available
    captureSentryException(error)
  }
}

export const submitToLocalStorage = <T>(data: T, key: OnboardingCacheKey) => {
  try {
    const newOnboardingData = { ...parseLocalStorage<T>(key), ...data }
    localStorage.setItem(key, JSON.stringify(newOnboardingData))
  } catch (error) {
    //skip if local storage is not available
    captureSentryException(error)
  }
}

export const clearOnboardingLocalStorage = (all = false) => {
  try {
    if (all) {
      Object.values(OnboardingCacheKey).forEach((key) => {
        localStorage.removeItem(key)
      })
    } else {
      Object.values(OnboardingCacheKey).forEach((key) => {
        if (CACHE_TO_CLEAR_ON_USER_CREATION.includes(key)) {
          localStorage.removeItem(key)
        }
      })
    }
  } catch (error) {
    //skip if local storage is not available
    captureSentryException(error)
  }
}

export const getOnboardingStepOrder = (
  onboardingType: OnboardingType,
  locale: Locale,
  key: string,
  profileType?: ProfileType
) => {
  if (!profileType) {
    return []
  } else if (isPayrollOnboarding(onboardingType)) {
    return ONBOARDING_STEP_ORDER[locale][`${profileType.toLowerCase()}-payroll`]
  } else {
    return ONBOARDING_STEP_ORDER[locale][`${profileType.toLowerCase()}-${key}`]
  }
}

export const isPayrollOnboarding = (onboardingType: OnboardingType) =>
  onboardingType === OnboardingType.Payroll

export type FieldMapper<T, R> = (data?: T) => R

/**
 * Converts Family profile data to OnboardingFamilyInput data format
 * @param data - The viewer's family profile data
 * @returns
 */
const familyExtractor = (data: {
  viewer: OnboardingViewerFragment
}): OnboardingFamilyInput | undefined => {
  const profile = data?.viewer?.user?.profile

  if (!profile) {
    return
  }

  const family = profile as OnboardingFamilyProfileFragment

  const familyInput: OnboardingFamilyInput = {
    firstName: profile.firstName,
    lastName: profile.lastName,
    children:
      family.children?.nodes?.map((child) => ({
        gender: child?.gender,
        monthsOldEnd: child?.monthsOldEnd,
        monthsOldStart: child?.monthsOldStart,
      })) ?? null,
  }

  if (family.address) {
    familyInput.address = {
      city: family.address.city.name,
      countrySlug: family.address.country.slug,
      lat: family.address.coordinates?.lat ?? null,
      lng: family.address.coordinates?.lng ?? null,
      neighborhood: family.address?.neighborhood?.name ?? null,
      postalCode: family.address?.postalCode ?? null,
      stateName: family.address.state.name,
      stateSlug: family.address.state.slug,
      street1: family.address?.street1 ?? null,
      street2: family.address.street2 ?? null,
    }
  }

  return familyInput
}

/**
 * Converts FamilyListing data to OnboardingFamilyInput data format
 * @param data - The viewer's family listing data
 * @returns
 */
const familyListingExtractor = (data: {
  viewer: OnboardingViewerFragment
}): OnboardingFamilyListingInput | undefined => {
  const listing = data?.viewer?.user?.profile?.listing as OnboardingFamilyListingFragment

  if (!listing) {
    return
  }

  return {
    description: listing.description,
    hourlyRate: listing.hourlyRate,
    hoursPerWeek: listing.hoursPerWeek,
    interestedInShare: listing.interestedInShare,
    fullTime: listing.fullTime,
    partTime: listing.partTime,
    occasionalWork: listing.occasionalWork,
  }
}

/**
 * Converts Nanny profile data to OnboardingNannyInput data format
 * @param data - The viewer's nanny profile data
 * @returns
 */
const nannyExtractor = (data: {
  viewer: OnboardingViewerFragment
}): OnboardingNannyInput | undefined => {
  const profile = data?.viewer?.user?.profile

  if (!profile) {
    return
  }

  const nanny = profile as OnboardingNannyProfileFragment

  const nannyInput: OnboardingNannyInput = {
    firstName: profile.firstName,
    lastName: profile.lastName,
  }

  if (nanny.address) {
    nannyInput.address = {
      city: nanny.address.city.name,
      countrySlug: nanny.address.country.slug,
      lat: nanny.address.coordinates?.lat ?? null,
      lng: nanny.address.coordinates?.lng ?? null,
      neighborhood: nanny.address?.neighborhood?.name ?? null,
      postalCode: nanny.address?.postalCode ?? null,
      stateName: nanny.address.state.name,
      stateSlug: nanny.address.state.slug,
      street1: nanny.address?.street1 ?? null,
      street2: nanny.address?.street2 ?? null,
    }
  }

  return nannyInput
}

/**
 * Converts NannyListing data to OnboardingNannyInput data format
 * @param data - The viewer's nanny listing data
 * @returns
 */
const nannyListingExtractor = (data: {
  viewer: OnboardingViewerFragment
}): OnboardingNannyListingInput | undefined => {
  const listing = data?.viewer?.user?.profile?.listing as OnboardingNannyListingInput

  if (!listing) {
    return
  }

  return {
    description: listing.description,
    hourlyRate: listing.hourlyRate,
    experience: listing.experience,
    cpr: listing.cpr,
    driversLicense: listing.driversLicense,
    firstAid: listing.firstAid,
    fluentEnglish: listing.fluentEnglish,
    fluentSpanish: listing.fluentSpanish,
    fullTime: listing.fullTime,
    partTime: listing.partTime,
    occasionalWork: listing.occasionalWork,
    gradeschoolerCare: listing.gradeschoolerCare,
    housekeeping: listing.housekeeping,
    infantCare: listing.infantCare,
    liveIn: listing.liveIn,
    mealPreparation: listing.mealPreparation,
    preschoolerCare: listing.preschoolerCare,
    specialNeeds: listing.specialNeeds,
    toddlerCare: listing.toddlerCare,
    transportation: listing.transportation,
    experienceChildren:
      listing.experienceChildren?.map((child) => ({
        ageGroup: child?.ageGroup ?? null,
      })) ?? null,
  }
}

/**
 * Converts User data to OnboardingUserInput data format
 * @param data - The viewer's User data
 * @returns
 */
const userExtractor = (data: {
  viewer: OnboardingViewerFragment
}): OnboardingUserInput | undefined => {
  const user = data?.viewer?.user

  if (!user) {
    return
  }

  return {
    email: user.email || null,
    phoneNumber: user.phoneNumber || null,
    smsEnabled: user.smsEnabled || null,
  }
}

/**
 * Converts Nanny payroll data to PayrollEmployeeRegistrationInput data format
 * @param data - The viewer's nanny profile data
 * @returns
 */
const nannyPayrollExtractor = (data: {
  viewer: PayrollOnboardingViewerFragment
}): OnboardingEmployeeInput | undefined => {
  const payrollNannyUser = data?.viewer.user as PayrollOnboardingUserFragment
  const payrollData = payrollNannyUser?.profile?.payroll

  if (!payrollData) {
    return
  }

  return {
    firstName: payrollData.firstName,
    middleName: payrollData.middleName,
    lastName: payrollData.lastName,
    phoneNumber: payrollData.phoneNumber,
    ssn: payrollData.ssn,
    birthdate: payrollData.birthdate,
    address: payrollData.address,
    bank: payrollData.bank,
  }
}

/**
 * Converts Family payroll data to OnboardingEmployerInput data format
 * @param data - The viewer's nanny profile data
 * @returns
 */
const familyPayrollExtractor = (data: {
  viewer: PayrollOnboardingViewerFragment
}): OnboardingEmployerInput | undefined => {
  const payrollFamilyUser = data?.viewer.user as PayrollOnboardingUserFragment
  const payrollData = payrollFamilyUser?.profile?.payroll
  const employerData = (payrollFamilyUser?.profile as PayrollOnboardingFamilyFragment)?.employer

  if (!payrollData) {
    return
  }

  return {
    firstName: payrollData.firstName,
    middleName: payrollData.middleName,
    lastName: payrollData.lastName,
    phoneNumber: payrollData.phoneNumber,
    ssn: payrollData.ssn,
    spouseFirstName: employerData?.spouseFirstName || undefined,
    spouseMiddleName: employerData?.spouseMiddleName || undefined,
    spouseLastName: employerData?.spouseLastName || undefined,
    spouseSsn: employerData?.spouseSsn || undefined,
    businessName: employerData?.businessName || undefined,
    businessContactFirstName: employerData?.businessContactFirstName || undefined,
    businessContactLastName: employerData?.businessContactLastName || undefined,
    businessFederalTaxId: employerData?.businessFederalTaxId || undefined,
    businessStateUnemploymentTaxId: employerData?.businessStateUnemploymentTaxId || undefined,
    businessStateTaxId: employerData?.businessStateTaxId || undefined,
    businessContactEmail: employerData?.businessContactEmail || undefined,
    businessContactPhoneNumber: employerData?.businessContactPhoneNumber || undefined,
    birthdate: payrollData?.birthdate || undefined,
    hourlyRate: employerData?.hourlyRate || undefined,
    hoursPerWeek: employerData?.hoursPerWeek || undefined,
    secondaryHourlyRate: employerData?.secondaryHourlyRate || undefined,
    secondaryHoursPerWeek: employerData?.secondaryHoursPerWeek || undefined,
    isNannyShare: employerData?.isNannyShare ?? undefined,
    nannyShareOtherFamilyLastName: employerData?.nannyShareOtherFamilyLastName || undefined,
    nannyShareOtherFamilyEmail: employerData?.nannyShareOtherFamilyEmail || undefined,
    nannyShareHourSubmissionType: employerData?.nannyShareHourSubmissionType || undefined,
    payCycle: employerData?.payCycle || undefined,
    startDate: employerData?.startDate || undefined,
    bank: payrollData.bank,
    address: payrollData.address,
  }
}

export const getExtractor = (onboardingCacheKey?: OnboardingCacheKey) => {
  switch (onboardingCacheKey) {
    case OnboardingCacheKey.Family:
      return familyExtractor
    case OnboardingCacheKey.FamilyListing:
      return familyListingExtractor
    case OnboardingCacheKey.Nanny:
      return nannyExtractor
    case OnboardingCacheKey.NannyListing:
      return nannyListingExtractor
    case OnboardingCacheKey.User:
      return userExtractor
    case OnboardingCacheKey.NannyPayroll:
      return nannyPayrollExtractor
    case OnboardingCacheKey.FamilyPayroll:
      return familyPayrollExtractor
  }
}

export const getStepTrackingMetadata = (
  currentStep?: OnboardingStep,
  profileType?: ProfileType
) => {
  return { step: currentStep, userType: profileType }
}

// returns the query params minus the profileType
export const getRouterParams = (routerQuery: ParsedUrlQuery) => {
  const { profileType, ...rest } = routerQuery
  return { ...rest }
}

export const stepSkipRules: Partial<
  Record<
    OnboardingStep,
    (
      profileType: ProfileType,
      getSavedValues: <T>(key: OnboardingCacheKey) => T,
      viewer: ViewerGlobalFragment,
      router: NextRouter
    ) => boolean
  >
> = {
  [OnboardingStep.Plus]: (_profileType, _getSavedValues, viewer) =>
    !!viewer?.user?.activeSubscription,
  [OnboardingStep.PlusPayment]: (_profileType, _getSavedValues, viewer) =>
    !!viewer?.user?.activeSubscription,
  [OnboardingStep.FEIN]: (_profileType, getSavedValues) =>
    !getSavedValues<OnboardingEmployerInput>(OnboardingCacheKey.FamilyPayroll)
      ?.businessFederalTaxId,
  [OnboardingStep.NannySharePayroll]: (_profileType, getSavedValues) =>
    !getSavedValues<OnboardingEmployerInput>(OnboardingCacheKey.FamilyPayroll)?.isNannyShare,
  [OnboardingStep.HourSubmission]: (_profileType, getSavedValues) => {
    const { nannyShareOtherFamilyEmail, nannyShareOtherFamilyLastName } =
      getSavedValues<OnboardingEmployerInput>(OnboardingCacheKey.FamilyPayroll)
    return !nannyShareOtherFamilyEmail && !nannyShareOtherFamilyLastName
  },
  [OnboardingStep.Invite]: (_profileType, _getSavedValues, _viewer, router) => {
    return !!router.query.token
  },
}
