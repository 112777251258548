import { ProfileType } from '@graph/types/global.generated'
import { isPayrollOnboarding } from 'onboarding/utils'
import { OnboardingType } from '@ui/onboarding/constants'

import {
  familyOnboardingPath,
  nannyOnboardingPath,
  onboardingPath,
  payrollFamilyOnboardingPath,
  payrollNannyOnboardingPath,
  payrollOnboardingPath,
} from './static'
import { routeWithParams } from './utils'

export const FORWARD = 'forward'

export const buildOnboardingPath = ({
  onboardingType,
  profileType,
  back,
  next,
  title,
  subStep,
}: {
  onboardingType?: OnboardingType
  profileType?: ProfileType
  back?: string
  next?: string
  title?: string
  subStep?: string
} = {}) => {
  let path
  if (onboardingType && isPayrollOnboarding(onboardingType)) {
    switch (profileType) {
      case ProfileType.Family:
      case ProfileType.ShareNanny:
        path = payrollFamilyOnboardingPath
        break
      case ProfileType.Nanny:
        path = payrollNannyOnboardingPath
        break
      default:
        path = payrollOnboardingPath
    }
  } else {
    switch (profileType) {
      case ProfileType.Family:
      case ProfileType.ShareNanny:
        path = familyOnboardingPath
        break
      case ProfileType.Nanny:
        path = nannyOnboardingPath
        break
      default:
        path = onboardingPath
    }
  }
  return routeWithParams(`${path}${subStep ? '/' + subStep : ''}`, { back, next, title })
}
